
import { defineComponent, ref, reactive, watch, inject } from 'vue';
import { Form } from 'ant-design-vue';
import { RuleObject } from 'ant-design-vue/es/form/interface';

const useForm = Form.useForm;

export default defineComponent({
  name: 'UploadAPKModal',
  props: {
    visible: {
      type: Boolean,
    },
  },
  emits: ['update:visible', 'ok'],
  setup(props, context) {
    const modelShow = ref<boolean>(false);
    watch(
      () => props.visible,
      () => {
        // console.log('watch',props.visible, modelShow.value);
        context.emit('update:visible', props.visible);
        modelShow.value = props.visible;
        if (props.visible) {
          formState.file = '';
          formState.fileTip = '';
          formState.fileName = '';
          formState.bit = '32';
        }
      }
    );
    watch(modelShow, () => {
      // console.log('watch2',props.visible, modelShow.value);
      context.emit('update:visible', modelShow.value);
    });

    const nameCheck = inject<any>('uploadApkModalCheck');

    const confirmLoading = ref<boolean>(false);

    const regPatName = /[^\u4e00-\u9fa5\w/.-]/g;
    const validateFile = async (rule: RuleObject, value) => {
      // console.log('validateFile', rule, value);
      let _fname = value.name || '';
      let _ext = _fname.substring(_fname.lastIndexOf('.') + 1);
      let _tmpfn = _fname.substr(0, _fname.lastIndexOf('.'));
      console.log(_ext, _tmpfn, _tmpfn.search(regPatName) !== -1);
      if (value === '') {
        formState.fileTip = '未选择上传的文件';
        return Promise.reject();
      } else if (value && value.size && value.size > 1024 * 1024 * 1024 * 3) {
        formState.fileTip = '上传APK文件不能不能大于3G';
        return Promise.reject();
      } else if (_tmpfn.search(regPatName) !== -1) {
        formState.fileTip = '文件名不符合规则，请重命名后再试';
        return Promise.reject();
      } else if (_ext != 'apk') {
        formState.fileTip = '仅支持apk文件';
        return Promise.reject();
      } else {
        const res = await nameCheck(formState);
        if (res == 2) {
          formState.fileTip = '文件已存在';
          return Promise.reject();
        } else if (res == 1) {
           formState.fileTip = '';
            return Promise.resolve();
        } else {
          formState.fileTip = res;
          return Promise.reject();
        }
      }
    };

    const formState = reactive({
      file: '',
      fileTip: '',
      fileName: '',
      bit: '32',
    });
    const rulesRef = reactive({
      file: [
        {
          validator: validateFile,
          trigger: 'blur',
        },
      ],
    });

    const { validate, validateInfos } = useForm(formState, rulesRef);

    const handleOk = () => {
      confirmLoading.value = true;
      validate()
        .then(() => {
          console.log('handleOk');
          context.emit('ok', formState);
          confirmLoading.value = false;
          modelShow.value = false;
        })
        .catch((err) => {
          confirmLoading.value = false;
          console.log('error', err);
        });
    };

    const uploading = (upload) => {
      // console.log(
      //   'uploading',
      //   upload,
      //   upload.file.name ? 'Flie-text' : 'upload'
      // );
      formState.file = upload.file;
      formState.fileName = upload.file.name;
      validate('file');
    };

    return {
      modelShow,
      formState,
      validateInfos,
      confirmLoading,
      uploading,
      handleOk,
    };
  },
});
