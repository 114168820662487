<template>
  <div class="rays-tuner-pat">
    <div class="pat-topbar">
      <div class="pat-title">PAT管理</div>
      <div class="pat-top-tools">
        <a-input
          placeholder="可输入名称关键词"
          v-model:value="search"
          class="pat-search"
          @press-enter="pressEhter"
        >
          <template #prefix>
            <svg-icon icon-class="search" class="top-icon" />
          </template>
        </a-input>
        <a-button class="pat-top-upload apk" @click="showUpApk = true">
          上传APK
        </a-button>
        <a-upload
          name="file"
          :showUploadList="false"
          :custom-request="uploadCheck"
        >
          <a-button class="pat-top-upload" @click="checkUpload">
            <div class="upload-icon-con">
              <svg-icon icon-class="newpage" class="top-icon" />
            </div>
            上传PAT
          </a-button>
        </a-upload>
      </div>
    </div>
    <div class="pat-body" id="pat_manager_scroll">
      <div class="pat-card-con" v-if="patlist.length > 0">
        <div
          class="pat-card"
          v-for="(item, index) in patlist"
          :key="index"
          @click="cardListClick(item)"
        >
          <div class="card-img-con">
            <!-- <img :src="item.package_icon" v-if="item.package_icon" /> -->
            <a-image
              :width="128"
              :height="128"
              :src="item.package_icon"
              :preview="false"
              :fallback="imgSvg"
            />
            <!-- <svg-icon icon-class="fps" class="img-nopic" v-else /> -->
            <div class="img-stat" v-if="item.stat != 0">
              <svg-icon
                icon-class="loading"
                class="img-stat-icon"
                v-show="item.stat == 3"
              />
              <a-progress
                :percent="item.percent"
                :status="item.progress"
                stroke-color="#604bdc"
                v-show="item.stat == 1"
              />
              <div class="progress_detail" v-show="item.stat == 1">
                {{ item.progDetail.show || '' }}
              </div>
              <svg-icon
                icon-class="redo"
                class="img-stat-icon-redo"
                v-show="[2, 4].includes(item.stat)"
              />
            </div>
          </div>
          <div class="card-detail">
            <div>
              <div class="card-name">
                <a-tooltip placement="bottomLeft" :destroyTooltipOnHide="true">
                  <template #title>{{ item.pat_name }}</template>
                  {{
                    [3, 4].includes(item.stat)
                      ? item.pat_name
                      : item.pat_name.substr(4)
                  }}
                </a-tooltip>
              </div>
            </div>
            <div class="card-time-con">
              <div class="card-time" v-if="item.stat == 0">
                {{ item.showTime }}
              </div>
              <div class="card-stat" v-else>
                <div v-show="item.stat == 1">正在上传...</div>
                <div v-show="item.stat == 2" class="card-stat-failed">
                  上传失败！
                </div>
                <div v-show="item.stat == 3">正在生成PAT...</div>
                <div v-show="item.stat == 4" class="card-stat-failed">
                  生成PAT失败！
                  <div v-show="item.redo > 0" style="display: inline;">
                    (重试{{ item.redo }}次)
                  </div>
                </div>
              </div>
              <a-dropdown
                class="card-drop-con"
                overlayClassName="rays-tuner-pat-edit-menu"
              >
                <a class="ant-dropdown-link" @click.prevent.stop>
                  <svg-icon icon-class="more2" class="top-icon card-drop" />
                </a>
                <template #overlay>
                  <a-menu @click="delConfirm(item, index)">
                    <a-menu-item
                      :key="item.pat_name"
                      :disabled="item.stat == 3"
                    >
                      <svg-icon icon-class="delete" class="top-icon card-del" />
                      删除
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="pat-card-empty" v-else>
        <div class="empty-con">
          <div class="empty-icon"></div>
          <div class="empty-tip">还没有PAT，快去上传吧</div>
          <a-upload
            name="file"
            :showUploadList="false"
            :custom-request="uploadCheck"
          >
            <a-button class="empty-upload pat" @click="checkUpload">
              上传PAT
            </a-button>
          </a-upload>
          <a-button class="empty-upload apk" @click="showUpApk = true">
            上传APK
          </a-button>
        </div>
      </div>
    </div>
    <div class="pat-footer">
      <a-pagination
        class="pat-footer-tool"
        size="small"
        show-quick-jumper
        v-model:current="current"
        :total="total"
        :pageSize="pageSize"
        @change="getPatList"
        v-show="total > 0"
      />
    </div>
    <UploadAPKModal v-model:visible="showUpApk" @ok="uploadApkOk" />
  </div>
</template>

<script>
import { CancelToken } from 'axios';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  createVNode,
  nextTick,
  computed,
  provide,
} from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
// import qs from "qs";
import router from '@/routes';
import { setPat, setPatList, setSimplifyHomeInfo } from '@/models/tunerStorage';
import store from '@/vuex/store';
import { message, Modal } from 'ant-design-vue';
import PerfectScrollbar from 'perfect-scrollbar';
import moment from 'moment';
import api from '@/api/pat-manager';
import taskApi from '@/api/task';
import UploadAPKModal from './components/UploadAPKModal.vue';
import { getBaseUrl } from '@/api/service';
import { debounce } from 'lodash';
import { imgSvg128_128 as imgSvg } from '@/utils/img';
const engineurl = getBaseUrl('engine');

const transDate = function(date) {
  let _time = date ? moment(date) : moment();
  let dateStr = _time.format('YYYY-MM-DD HH:mm:ss');
  return dateStr;
};
export default defineComponent({
  name: 'Pat',
  components: {
    UploadAPKModal,
  },
  setup() {
    let patBodyDom = null;
    let patBodyPs = null;
    const search = ref('');
    const current = ref(1);
    const pageSize = ref(18);
    const total = ref(0);
    const patlist = computed(() => store.state.shaderTool.patList || []);

    const $_user = store.state.user.userinfo;
    const $_email = $_user.email;

    const simplifyHome = computed(
      () => store.state.shaderTool.simplifyHome || {}
    );

    const patListInfo = computed(
      () => store.state.shaderTool.patListInfo || {}
    );

    const setInfoUploadTip = (stat) => {
      const _info = JSON.parse(JSON.stringify(patListInfo.value));
      _info.uploadTip = stat || false;
      store.commit('setPatListInfo', _info);
    };

    const getPageCount = async () => {
      const params = {
        user: $_email,
        pattern: search.value || '.*',
      };
      const res = await api.getPatCount(params);
      if (!res) return;
      if (res.code == 0) {
        total.value = res.count || 0;
      } else {
        message.error('获取PAT列表总数失败');
      }
    };

    /** 更新pat列表方法 */
    const getPatList = async () => {
      const params = {
        user: $_email,
        pattern: search.value || '.*',
        offset: pageSize.value * (current.value - 1),
        count: pageSize.value,
      };
      const res = await api.getPatList(params);
      if (!res) return;
      if (res.code != 0) {
        message.error('获取PAT列表失败');
        return;
      }

      const _list = JSON.parse(res.result_list);
      console.log('getPatList', _list, JSON.parse(res.result_list));
      const _editList = patlist.value.filter((item) =>
        [1, 2].includes(item.stat)
      );
      if (_editList.length > 0) {
        setInfoUploadTip(true);
      } else {
        setInfoUploadTip(false);
      }
      const _editApkList = patlist.value.filter((item) =>
        [3, 4].includes(item.stat)
      );
      for (let i = 0; i < _list.length; i++) {
        if (_list[i].package_icon) {
          _list[i].package_icon =
            engineurl +
            '/api/sopt/simplifier/getPackageRelatedResources?package_name=' +
            _list[i].package_icon;
        }
        const _ext = _list[i].pat_name.substring(
          _list[i].pat_name.lastIndexOf('.') + 1
        );
        _list[i].stat = 0;
        if (_ext == 'apk') {
          _list[i].stat = 3;
          if (_list[i].from_apk) {
            _list[i].stat = 4;
          }
        }
        const _time = parseInt(_list[i].time_upload);
        _list[i].showTime = transDate(_time);
        _list[i].percent = 100;
        _list[i].progress = 'success';
        _list[i].progDetail = {};
        // for (let j = 0; j < _editList.length; j++) {
        //    if (_editList.pat_name == _list[i].pat_name) {
        //       _editList.splice(i,1);
        //       break;
        //    }
        // }
        while (_editList[0] && _time < _editList[0].time_upload) {
          // if (_list[i - 1].pat_name == _editList[0].pat_name) {
          //   _list.splice(i - 1, 1, _editList.shift());
          // } else {
          _list.splice(i, 0, _editList.shift());
          // }
          i++;
        }

        _list[i].redo = 0;
        for (let index = 0; index < _editApkList.length; index++) {
          if (_editApkList[index].pat_name == _list[i].pat_name) {
            _list[i].redo = _editApkList[index].redo || 0;
            _editApkList.splice(index, 1);
            index--;
          }
        }
      }
      setPatList(_list);

      if (patBodyPs && patBodyDom) {
        nextTick(() => {
          patBodyPs.update();
        });
      }
    };

    const pressEhter = () => {
      getPageCount();
      getPatList();
    };

    const checkUpload = (event) => {
      let _count = 0;
      for (let i = 0; i < patlist.value.length; i++) {
        if (patlist.value[i].stat == 1) {
          _count++;
        }
      }
      if (_count >= 2) {
        message.warning('最多同时上传2个PAT！');
        event.stopPropagation();
      }
    };

    const transformBytes = (bytes) => {
      const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']; //  单位的合集
      for (let i = 0; i < units.length; i++) {
        if (bytes < Math.pow(1024, i + 1)) {
          return Math.round(bytes / Math.pow(1024, i)) + units[i];
        }
      }
    };

    const transformTimes = (second) => {
      if (second == -1) {
        return '未知';
      }
      const units = ['秒', '分', '小时', '天'];
      let _time = 0;
      let _index = 0;
      if (second < 60) {
        _time = second;
      } else if (second < 3600) {
        _time = second / 60;
        _index = 1;
      } else if (second < 86400) {
        _time = second / 3600;
        _index = 2;
      } else {
        _time = second / 86400;
        _index = 3;
      }
      return Math.round(_time) + units[_index];
    };

    /** 上传文件请求方法及进度条计算 */
    const uploadingFile = async (file, item, isApk) => {
      console.log('uploadingFile', file, isApk, patlist);
      // item.progDetail.startime = 0;
      item.progDetail.lasttime = 0;
      item.progDetail.lastloaded = 0;
      const params = {
        user: $_email,
        user_id: $_user.uid,
        file: file,
        isFormData: true,
      };
      let _fun = 'uploadPat';
      let _msg = 'PAT上传';
      if (isApk) {
        _fun = 'uploadApk';
        _msg = 'APK上传';
        params.bit = item.bit;
      } else {
        params.pat = file.name;
      }
      const res = await api[_fun](params, {
        noMessage: true,
        cancelToken: new CancelToken((cancel) => {
          item.cancel = cancel;
        }),
        onUploadProgress: function(progressEvent) {
          let complete =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          item.percent = complete;
          // if (!item.progDetail.startime) {
          //    item.progDetail.startime = progressEvent.timeStamp;
          // }
          let _sptime = 1000;
          if (item.progDetail.lasttime) {
            _sptime = progressEvent.timeStamp - item.progDetail.lasttime;
          }
          let _spload = progressEvent.loaded;
          if (item.progDetail.lastloaded) {
            _spload = progressEvent.loaded - item.progDetail.lastloaded;
          }
          let _speed = (_spload / _sptime) * 1000;

          let _leftTime = -1;
          if (_speed > 0) {
            _leftTime = (progressEvent.total - progressEvent.loaded) / _speed;
          }

          item.progDetail.lasttime = progressEvent.timeStamp;
          item.progDetail.lastloaded = progressEvent.loaded;
          item.progDetail.show =
            '剩余' +
            transformTimes(_leftTime) +
            '—' +
            transformBytes(progressEvent.loaded) +
            '/' +
            transformBytes(progressEvent.total) +
            '(' +
            transformBytes(_speed) +
            '/秒)';
          store.commit('setPatListItem', item);
        },
      });
      if (!res) {
        item.stat = 2;
        item.progress = 'exception';
        message.error(_msg + '失败！');
        store.commit('setPatListItem', item);
        return;
      }
      if (res.code == 0) {
        item.progress = 'success';
        nextTick(() => {
          setTimeout(() => {
            item.stat = 0;
            getPageCount();
            getPatList();
            message.success(_msg + '成功！');
          }, 1000);
        });
      } else {
        item.stat = 2;
        item.progress = 'exception';
        message.error(_msg + '失败！' + res.detail);
      }
      store.commit('setMailUpdateTime', new Date().getTime());
      store.commit('setPatListItem', item);
    };

    const regPatName = /\W/g;
    /** pat上传前的文件验证 */
    const uploadCheck = async (upload) => {
      let _fname = upload.file.name;
      let _ext = _fname.substring(_fname.lastIndexOf('.') + 1);
      let _tmpfn = _fname.substr(0, _fname.lastIndexOf('.'));
      let _patName = _tmpfn.substring(_tmpfn.lastIndexOf('.') + 1);
      if (_patName.search(regPatName) !== -1) {
        message.error('上传PAT文件名称不能有特殊字符，只能数字字母下划线。');
        return;
      }
      if (_ext != 'pat') {
        message.error('非法的PAT文件后缀名！');
        return;
      }
      if (
        upload.file &&
        upload.file.size &&
        upload.file.size < 1024 * 512 &&
        upload.file.size > 1024 * 1024 * 1024 * 3
      ) {
        message.error('上传PAT文件不能小于512KB且不能大于3G');
        return;
      }
      const params = {
        user: $_email,
        pat: upload.file.name,
      };
      const res = await api.verifypat(params);
      if (!res) return;
      if (res.code == 0) {
        uploading(upload.file);
      } else {
        message.error(res.detail || '上传PAT失败!');
      }
    };

    /**上传时创建临时pat项 */
    const uploading = (file, bit) => {
      let _tmpfn = file.name.substr(0, file.name.lastIndexOf('.'));
      let _pkgname = _tmpfn.substring(0, _tmpfn.lastIndexOf('.'));
      const _list = JSON.parse(JSON.stringify(patlist.value));
      _list.unshift({
        package_icon:
          engineurl +
          '/api/sopt/simplifier/getPackageRelatedResources?package_name=' +
          _pkgname,
        package_name: _pkgname,
        pat_name: file.name,
        time_upload: new Date().getTime(),
        showTime: transDate(),
        percent: 0,
        progress: 'active',
        progDetail: { startime: 0, lasttime: 0, lastloaded: 0, show: '' },
        stat: 1,
        tmpFile: file,
        bit: bit || 0,
      });
      setPatList(_list);
      setInfoUploadTip(true);
      uploadingFile(file, patlist.value[0], bit ? true : false);
    };

    /**点击卡片(stat)  2 重新上传  4 重新生成pat   0 跳转到shader页面*/
    const cardListClick = debounce(
      async (item) => {
        if (item.stat == 2) {
          item.percent = 0;
          item.stat = 1;
          uploadingFile(item.tmpFile, item, item.bit ? true : false);
        } else if (item.stat == 4) {
          if (item.redo >= 3) {
            message.warn(
              '重试超过3次，apk可能存在问题，请检查后重新上传或者联系管理员！'
            );
            return;
          }
          const _tid = item.package_name.substr(8);
          const params = {
            user: $_email,
            task_id: _tid,
          };
          const url = `/api/sopt/simplifier/continueAsyncTask`;
          const res = await taskApi.handleTask(url, params, {
            noMessage: true,
          });
          if (res && res.code == 0) {
            nextTick(() => {
              setTimeout(() => {
                item.redo = item.redo + 1;
                getPageCount();
                getPatList();
                message.success('重新启动生成PAT任务成功！');
              }, 1000);
            });
          } else {
            message.error('重新启动生成PAT任务失败！');
          }
        } else if (item.stat == 0) {
          setPat(item);
          router.push({
            path: '/mainhome/simplifyhome/shader',
          });
        }
      },
      300,
      { leading: true, trailing: false }
    );

    const delPat = async (pat) => {
      if (!pat) return;
      const params = {
        user: $_email,
        user_id: $_user.uid,
        pat: pat.pat_name,
        delete_type: false,
      };
      let _msg = 'PAT';
      if (pat.stat == 4) {
        params.delete_type = true;
        _msg = 'APK';
      }
      const res = await api.deletePat(params);
      if (!res) return;
      if (res.code == 0) {
        message.success(`删除${_msg}成功！`);
        getPageCount();
        getPatList();
      } else {
        message.error(`删除${_msg}失败：${res.detail}`);
      }
    };

    const delConfirm = (pat, i) => {
      Modal.confirm({
        title: '删除PAT',
        icon: createVNode(ExclamationCircleOutlined),
        content: '确定要删除该PAT吗？（' + pat.pat_name + '）',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve) => {
            if ([0, 4].includes(pat.stat)) {
              delPat(pat);
            } else {
              if (pat.cancel) {
                pat.cancel();
              }
              const _list = JSON.parse(JSON.stringify(patlist.value));
              _list.splice(i, 1);
              setPatList(_list);
              setInfoUploadTip(false);
              for (let i = 0; i < patlist.value.length; i++) {
                if ([1, 2].includes(patlist.value[i].stat)) {
                  setInfoUploadTip(true);
                  break;
                }
              }
            }
            resolve();
          });
        },
      });
    };

    const showUpApk = ref(false);
    const checkExistFun = async (formState) => {
      console.log('checkExistFun', formState);

      const params = {
        user: $_email,
        apk: formState.file.name,
      };

      const res = await api.verifyApk(params);
      if (res && res.code == 0) {
        return 1;
      } else if (res && res.code == 29) {
        return 2;
      } else {
        return res.detail;
      }
    };
    provide('uploadApkModalCheck', checkExistFun);
    const uploadApkOk = (formState) => {
      console.log('uploadApkOk', formState);
      uploading(formState.file, formState.bit);
    };

    onMounted(() => {
      // 初始化PAT列表scroll
      patBodyDom = document.querySelector('#pat_manager_scroll');
      patBodyPs = new PerfectScrollbar(patBodyDom);
      setPat({});
      const _simp = JSON.parse(JSON.stringify(simplifyHome.value));
      _simp.menuKey = ['2'];
      setSimplifyHomeInfo(_simp);
    });
    onBeforeUnmount(() => {
      if (patBodyPs) {
        patBodyPs.destroy();
        patBodyPs = null;
      }
      patBodyDom = null;
    });
    getPageCount();
    getPatList();
    return {
      search,
      current,
      pageSize,
      total,
      patlist,
      showUpApk,
      imgSvg,
      getPatList,
      pressEhter,
      cardListClick,
      uploadCheck,
      checkUpload,
      delConfirm,
      uploadApkOk,
    };
  },
});
</script>

<style lang="scss" scoped>
.rays-tuner-pat {
  width: 100%;
  height: 100%;
  padding: 20px 39px 24px 39px;
  background-color: #fff;
}
.pat-topbar {
  width: 100%;
  height: 32px;
}
.pat-title {
  width: 64px;
  height: 32px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: LEFT;
  color: #222222;
  line-height: 32px;
  float: left;
}
.pat-top-tools {
  width: 594px;
  height: 32px;
  float: right;
  display: flex;
  /* border: 1px solid black; */
}
.top-icon {
  width: 16px;
  height: 16px;
}
.pat-search {
  width: 320px;
  height: 32px;
  margin-right: 32px;
  border-radius: 4px;
}
.pat-search:hover {
  border-color: #8877ff;
}
.pat-search:focus {
  border-color: #8877ff;
  box-shadow: 0 0 0 2px rgba(136, 119, 255, 0.2);
}
.pat-search .top-icon {
  color: #8c8c8c;
}
.pat-search.ant-input-affix-wrapper-focused {
  border-color: #8877ff;
  box-shadow: 0 0 0 2px rgba(136, 119, 255, 0.2);
}
.pat-top-upload {
  width: 124px;
  height: 32px;
  border-radius: 4px;
  color: #fff;
  background: #604bdc;
  border-color: #604bdc;
  &.apk {
    width: 102px;
    margin-right: 16px;
  }
}
.upload-icon-con {
  display: inline-block;
  width: 22px;
  height: 19px;
  vertical-align: middle;
}
.pat-body {
  position: relative;
  width: calc(100%);
  height: calc(100% - 128px);
  margin-top: 36px;
  /* border: 1px solid black; */
}
.pat-card-con {
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  margin-top: -40px;
  margin-left: -40px;
}
.pat-card {
  width: 239px;
  height: 224px;
  float: left;
  margin-top: 40px;
  margin-left: 40px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  cursor: pointer;
}
.pat-card-empty {
  position: relative;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
}
.empty-con {
  position: absolute;
  left: calc(50% - 90px);
  top: calc(50% - 112px);
  width: 160px;
  height: 246px;
}
.empty-icon {
  width: 79px;
  height: 79px;
  margin-left: 40px;
  background: url('~@/assets/Inbox.png') no-repeat;
  border: 1px dashed rgba(0, 0, 0, 0.2);
}
.empty-tip {
  margin-top: 8px;
  color: #666666;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: CENTER;
}
.empty-upload {
  width: 160px;
  height: 48px;
  margin-top: 16px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 500;
  border-radius: 4px;
  &.pat {
    margin-top: 24px;
    color: #fff;
    background: #604bdc;
    border-color: #604bdc;
  }
}
.card-img-con {
  position: relative;
  width: 100%;
  height: 158px;
  padding-top: 16px;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
  background: #222;
  /* background: url('~@/assets/logo.png') no-repeat center; */
  /* background-size: 128px, 128px; */
}
.card-img-con img {
  border-radius: 16px;
}
.img-nopic {
  width: 32px;
  height: 32px;
  margin-top: 48px;
  color: #3c3c3c;
}
.img-stat {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
}
.img-stat .ant-progress {
  margin-top: 66px;
  margin-left: 20px;
  width: 190px;
}
.progress_detail {
  color: #aaa;
}
.img-stat-icon {
  width: 32px;
  height: 32px;
  margin-top: 64px;
  animation: turn 2s linear infinite;
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.img-stat-icon-redo {
  width: 24px;
  height: 24px;
  margin-top: 68px;
}
.card-detail {
  width: 100%;
  height: 64px;
  padding: 8px 8px 8px 6px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: LEFT;
  background-color: #fff;
}
.card-name {
  width: 100%;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  color: #000;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-time-con {
  margin-top: 5px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #aaa;
  line-height: 20px;
}
.card-time {
  width: 180px;
  float: left;
}
.card-stat {
  width: 180px;
  float: left;
  color: #604bdc;
}
.card-stat-failed {
  color: #d01010;
}
.card-drop-con {
  float: right;
}
.card-drop {
  margin-top: 3px;
  color: #d8d8d8;
}
.card-del {
  margin-top: -5px;
  vertical-align: middle;
}
.pat-footer {
  width: 100%;
  height: 32px;
  padding-top: 3px;
  margin-top: 28px;
}
.pat-footer-tool {
  float: right;
}
</style>
<style lang="scss">
.rays-tuner-pat .card-img-con .ant-image {
  img {
    border-radius: 28px;
  }
}
.rays-tuner-pat-edit-menu > .ant-dropdown-menu {
  width: 84px;
}
.rays-tuner-pat-edit-menu .ant-dropdown-menu-item {
  color: #aaa;
}
.rays-tuner-pat-edit-menu .ant-dropdown-menu-item:hover {
  color: #ff2222;
}

.rays-tuner-pat .img-stat span.ant-progress-text {
  color: #604bdc;
}
</style>
